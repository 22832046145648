<template>
    <main class="tone">
        <router-view class="tone__data" />
    </main>
</template>

<script>
    export default {
        name: "Tone",
    }
</script>
